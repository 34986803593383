import React from "react";
import Layouts from "../layouts/Layouts";
import { EmployeeSingleWorkProgress } from "../components/customs/WorkProgress";

// type Props = {}

const SingleWorkProgress = () => {
  return (
    <Layouts>
      <EmployeeSingleWorkProgress />
    </Layouts>
  );
};

export default SingleWorkProgress;
