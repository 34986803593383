import React from "react";
import WorkProgressTable from "./WorkProgressTable";

type EmployeeWorkProgressProps = {
  workProgress: any;
  singleData: any;
  getWorkProgress: any;
  years: any;
  setYears: any;
  getYear: any;
  dates: any;
  search: any;
};

const EmployeeWorkProgress: React.FC<EmployeeWorkProgressProps> = ({
  workProgress,
  singleData,
  getWorkProgress,
  years,
  setYears,
  getYear,
  dates,
  search,
}) => {
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Work Progress</h4>
          </div>
        </div>

        <div className="row filter-row">
          <div className="col-sm-6 col-md-4">
            <div className="form-group "></div>
          </div>

          <div className="col-sm-6 col-md-4">
            <div className="form-group  ">
              <input
                type="month"
                className="select floating w-100 form-control"
                value={years}
                onChange={(e) => setYears(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <a className="btn btn-success btn-block" onClick={getYear}>
              Search
            </a>
          </div>
        </div>

        {/*<div className="row filter-row">
          <div className="col-sm-6 col-md-3">
            <div className="form-group ">
              <input
                type="text"
                className="form-control floating"
                placeholder="Employee ID"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="form-group ">
              <input
                type="text"
                className="form-control floating"
                placeholder="Employee Name"
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="form-group  select-focus">
              <select className="select floating w-100 form-control">
                {EmployeeDetails &&
                  EmployeeDetails.map((item) => {
                    return <option key={item.id}>{item.option}</option>;
                  })}
              </select>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <a href="#" className="btn btn-success btn-block">
              Search
            </a>
          </div>
        </div> */}
        {workProgress.length > 0 ? (
          <WorkProgressTable
            workProgress={workProgress}
            singleData={singleData}
          />
        ) : (
          <h3 className="d-flex justify-content-center">No data found!</h3>
        )}
      </div>
    </div>
  );
};

export default EmployeeWorkProgress;
