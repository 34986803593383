/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import instance from "../../../../API/apiCall";
import Layouts from "../../../layouts/Layouts";
import { APILINK } from "../../../Utilities/ApiLink";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import Moment from "moment";
import { BsCaretDownFill } from "react-icons/bs";
import Button from "../../basics/button/Button";

// type Props = {};

const AddLeave = () => {
  const navigate = useNavigate();
  const leave = [
    {
      id: 1,
      option: "Leave Type",
      hidden: true,
      status: "Leave Status",
    },
    {
      id: 2,
      option: "Casual leave",
      hidden: false,
      status: "Pending",
    },
    {
      id: 3,
      option: "Medical Leave",
      hidden: false,
      status: "Approved",
    },
    {
      id: 4,
      option: "Loss of pay",
      hidden: false,
      status: "Declined",
    },
  ];
  const [numOfDays, setNumOfDays] = useState(0);
  const [fromDate, setFromDate] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(Moment(new Date()).format("YYYY-MM-DD"));

  const [empLeaveType, setEmpLeaveType] = useState([]);

  const [addLeaves, setAddLeaves] = useState({
    leave_type: "",
    number_of_days: "",
    /* remaining_leaves: "", */
    leave_reason: "",
    subject: "",
  });
  const [permissionCheck, setPermissionCheck] = useState(false);

  const addLeavesForm = Yup.object().shape({
    leave_type: Yup.string().required("Leave type is required"),
    leave_reason: Yup.string().required("Leave reason is required"),
    subject: Yup.string().required("Subject is required"),
  });

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_Id");
  const leave_Id = localStorage.getItem("leave_Id");

  const handleCheckbox = (e: any) => {
    console.log(e.target.checked, "e.target.checked");
    if (e.target.checked) {
      setPermissionCheck(true);
    } else setPermissionCheck(false);
  };

  // get single leave
  const getSingleLeave = () => {
    const params = {
      id: leave_Id,
    };
    instance({
      url: APILINK.leave_list,
      method: "GET",
      params: params,
      headers: {
        token: token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data[0];
          if (response.data.data.length > 0) {
            setAddLeaves({
              ...responseData,
              from_date: Moment(responseData.from_date).format("DD-MM-YYYY"),
              to_date: Moment(responseData.to_date).format("DD-MM-YYYY"),
              leave_reason: responseData.reason,
            });
            setFromDate(Moment(responseData.from_date).format("YYYY-MM-DD"));
            setToDate(Moment(responseData.to_date).format("YYYY-MM-DD"));
            setNumOfDays(responseData.no_of_days);
          } else if (parseInt(response.data.status) === 401) {
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  // add and update api call

  const addLeave = async (values: any) => {
    const param = {
      emp_id: userId,
      leave_type: parseInt(values.leave_type),
      from_date: fromDate,
      to_date: toDate,
      reason: values.leave_reason,
      no_of_days: permissionCheck == true ? 0.5 : numOfDays,
      subject: values.subject,
    };

    await instance({
      // url of the api endpoint (can be changed)
      url: leave_Id
        ? `${APILINK.leave_list}/id=${leave_Id}`
        : APILINK.leave_list,
      method: leave_Id ? "PUT" : "POST",
      data: leave_Id ? { ...param, id: leave_Id } : param,
      headers: {
        token: token,
      },
    })
      .then(async (response) => {
        if (response.status == 200) {
          navigate("/leaves");
          localStorage.setItem("leave_Id", "");
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  //leave type api
  const employeeLeaveType = async () => {
    await instance({
      url: APILINK.leave_type,
      method: "GET",
      headers: {
        token: token,
      },
    }).then(async (response) => {
      if (response.status == 200) {
        if (response.data.status !== 400) {
          const empArray: any = [];
          empArray.push({
            label: "Leave Type",
            value: "",
            days: "",
            hidden: true,
          });
          response.data.data.map((item: any) => {
            (item.emp_taken_leave === null ? 0 : item.emp_taken_leave) <=
              item.total_days &&
              empArray.push({
                label: item.leave_type,
                value: item.id,
                days: item.total_days,
                taken_leave:
                  item.emp_taken_leave === null ? 0 : item.emp_taken_leave,
              });
            setEmpLeaveType(empArray);
          });
        } else if (parseInt(response.data.status) === 401) {
          window.location.href = "/";
        }
      }
    });
  };
  // No of days calculation
  useEffect(() => {
    const from_date = Moment(fromDate, "YYYY-MM-DD").toDate().getTime();
    const to_date = Moment(toDate, "YYYY-MM-DD").toDate().getTime();
    const times = to_date - from_date;
    const no_of_days = times / (1000 * 3600 * 24);
    setNumOfDays(no_of_days + 1);
  }, [fromDate, toDate]);

  useEffect(() => {
    leave_Id && getSingleLeave();
    employeeLeaveType();
  }, []);

  return (
    <Layouts>
      <div className="page-wrapper ">
        <div className="content">
          <Formik
            initialValues={addLeaves}
            onSubmit={(values) => addLeave(values)}
            validationSchema={addLeavesForm}
            enableReinitialize={true}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <h4 className="page-title">
                      {leave_Id ? "Edit Leave" : "Add Leave"}
                    </h4>
                  </div>
                </div>
                <form>
                  <div className="card-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                Leave Type
                                <span className="text-danger">*</span>
                              </label>
                              <div className="select floating css-2b097c-container">
                                <div className=" css-yk16xz-control position-relative">
                                  <div className=" css-1hwfws3">
                                    <div className="form-group  select-focus">
                                      <select
                                        className="select floating w-100 form-control icons"
                                        onChange={handleChange("leave_type")}
                                        onBlur={handleBlur("leave_type")}
                                        value={values.leave_type}
                                      >
                                        {empLeaveType?.map((item: any) => {
                                          return (
                                            <option
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {`${item.label} ${item.days}`}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <BsCaretDownFill className="search-iconone position-absolute" />

                                      {errors.leave_type &&
                                        touched.leave_type && (
                                          <p className="text-danger">
                                            {errors.leave_type}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group width100">
                                <label>
                                  Subject
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="textarea"
                                  className="form-control"
                                  onChange={handleChange("subject")}
                                  onBlur={handleBlur("subject")}
                                  value={values.subject}
                                />

                                {errors.subject && touched.subject && (
                                  <p className="text-danger">
                                    {errors.subject}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>From</label>
                                <div className="">
                                  <div className="react-datepicker-wrapper">
                                    <div className="react-datepicker__input-container">
                                      <input
                                        type="date"
                                        className="form-control datetimepicker"
                                        onChange={(e) =>
                                          setFromDate(e.target.value)
                                        }
                                        value={fromDate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>To</label>
                                <div className="">
                                  <div className="react-datepicker-wrapper">
                                    <div className="react-datepicker__input-container">
                                      <input
                                        type="date"
                                        className="form-control datetimepicker"
                                        onChange={(e) =>
                                          setToDate(e.target.value)
                                        }
                                        value={toDate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Number of days</label>
                                <label className="form-control">
                                  {permissionCheck == true ? 0.5 : numOfDays}
                                </label>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group width100">
                                <label>
                                  Leave Reason
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  className="form-control"
                                  onChange={handleChange("leave_reason")}
                                  onBlur={handleBlur("leave_reason")}
                                  value={values.leave_reason}
                                />

                                {errors.leave_reason &&
                                  touched.leave_reason && (
                                    <p className="text-danger">
                                      {errors.leave_reason}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group d-flex">
                                <div className="pr-2">
                                  <div className="react-datepicker-wrapper">
                                    <div className="react-datepicker__input-container">
                                      <input
                                        type="checkbox"
                                        onChange={(e) => handleCheckbox(e)}
                                        checked={permissionCheck}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <label>Half day leave</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center m-t-20">
                    <Button
                      type="submit"
                      className="btn btn-primary submit-btn"
                      onClick={() => handleSubmit()}
                      text="Save"
                    />
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Layouts>
  );
};

export default AddLeave;
