import React, { useState } from "react";
import Moment from "moment";

type SingleWorkProgressTableProps = {
  singleData: any;
};

const SingleWorkProgressTable: React.FC<SingleWorkProgressTableProps> = ({
  singleData,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped custom-table">
            <thead>
              <tr>
                <th>Employee Id</th>
                <th>Date</th>
                <th>Day</th>
                <th>In Time</th>
                <th>Out Time</th>
                <th>Hours Worked</th>
              </tr>
            </thead>
            <tbody>
              {singleData?.map((item: any) => {
                return (
                  <tr key={item.emp_id}>
                    <td>{item.emp_id}</td>
                    <td>
                      {item.worked_hours?.date &&
                        Moment(item.worked_hours?.date).format("DD-MM-YYYY")}
                    </td>
                    <td>{item.worked_hours?.day}</td>
                    <td>{item.worked_hours?.in_time}</td>
                    <td>
                      {item.worked_hours?.out_mode == 0
                        ? "-"
                        : item.worked_hours?.out_time}
                    </td>
                    <td>
                      {item.worked_hours?.worked_hours &&
                        `${Moment(
                          item.worked_hours?.worked_hours,
                          "HH:mm:ss"
                        ).hours()} Hours ${Moment(
                          item.worked_hours?.worked_hours,
                          "HH:mm:ss"
                        ).minutes()} Minutes`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SingleWorkProgressTable;
